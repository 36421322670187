import { FC, useState, useEffect, useCallback, useContext } from 'react';
import { nanoid } from 'nanoid';
import { io } from 'socket.io-client';
import { SOCKET_SERVICE_URL } from '@configuration/client';
import useNoodleApi from '@hooks/useNoodleApi';
import getSocketToken from '@tsClient/getSocketToken';
import joinSocketChannels from '@tsClient/joinSocketChannels';
import { useUser } from '@hooks';
import * as Sentry from '@sentry/nextjs';
import TeamsContext from '@providers/Teams/TeamsContext';
import SocketContext, { SocketMessage, SocketMessageType } from './SocketContext';

export { default as isInboxUpdatedMessageData } from './isInboxUpdatedMessageData';

const socket = io(SOCKET_SERVICE_URL);

type SocketListener = {
  id: string;
  messageType: string;
  fn: (data: unknown) => unknown;
};

const SocketProvider: FC = ({ children }) => {
  const [listeners, setListeners] = useState<SocketListener[]>([]);

  const [user] = useUser();
  const { creatorId } = useContext(TeamsContext);

  const { data: token, getData: getTokenFn } = useNoodleApi(getSocketToken);
  const { getData: joinChannelFn } = useNoodleApi(joinSocketChannels);

  const addListener = useCallback(({ fn, messageType }: { fn: (data: unknown) => unknown, messageType: SocketMessageType }): string => {
    const listenerId = nanoid();
    setListeners((prev) => [...prev, {
      fn,
      id: listenerId,
      messageType,
    }]);
    return listenerId;
  }, []);

  const removeListener = useCallback((id: string): void => setListeners((prev) => prev.filter((l) => l.id !== id)), []);

  useEffect(() => {
    if (user?.id) {
      getTokenFn({ creatorId });
    }
  }, [creatorId, user, getTokenFn]);

  useEffect(() => {
    if (token) {
      socket.emit('login', token);
    }
  }, [token]);

  useEffect(() => {
    socket.on('error', (data) => Sentry.captureException(data.error));
  }, []);

  useEffect(() => {
    socket.removeAllListeners('message');
    socket.on('message', (message: SocketMessage) => {
      listeners.filter((l) => l.messageType === message.type)
        .forEach((listener) => listener.fn(message.data));
    });
    return () => {
      socket.removeAllListeners('message');
    };
  }, [listeners]);

  const joinChannels = useCallback(async (channelIds: string[]) => {
    await joinChannelFn({ channels: channelIds });
  }, [joinChannelFn]);

  return (
    <SocketContext.Provider
      value={{
        addListener,
        isInitialized: Boolean(token),
        joinChannels,
        removeListener,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;

export {
  SocketMessageType,
};
