/* eslint-disable import/no-unused-modules */
import { z } from 'zod';
import { SocketMessageType } from './SocketContext';

export const newChatMessageData = z.object({
  data: z.object({
    authorId: z.string(),
    messageId: z.string(),
  }),
  messageType: z.literal(SocketMessageType.NEW_CHAT_MESSAGE),
});
export type NewChatMessageData = z.infer<typeof newChatMessageData>['data'];

export const userInboxUpdatedMessageSchema = z.object({
  data: z.object({
    inboxItemId: z.string(),
    isInboxItemRead: z.boolean(),
  }),
  messageType: z.literal(SocketMessageType.USER_INBOX_UPDATED),
});
export type UserInboxUpdatedMessageData = z.infer<typeof userInboxUpdatedMessageSchema>['data'];