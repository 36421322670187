import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/user-workflows/{userWorkflowId}/notes';
type THIS_API = ApiDefinition['/workflows/user-workflows/{userWorkflowId}/notes']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getUserWorkflowNotes = async ({ userWorkflowId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { userWorkflowId });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getUserWorkflowNotes;
