import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/user-workflows/{userWorkflowId}/notes/{messageId}';
type THIS_API = ApiDefinition['/workflows/user-workflows/{userWorkflowId}/notes/{messageId}']['PUT'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const updateUserWorkflowNote = async ({ userWorkflowId, messageId, ...body }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { messageId, userWorkflowId });
  const response = await noodleApiClient.put(path, body);
  return response.data;
};

export default updateUserWorkflowNote;
