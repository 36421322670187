import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisRequest = ApiDefinition['/ai/filing-county']['GET'];
type Input = ThisRequest['query'];
type Output = ThisRequest['response'];

const getFilingCounty = async ({ zipcode }: Input): Promise<Output> => {
  const response = await noodleApiClient.get('/ai/filing-county', {
    params: { zipcode },
  });
  return response.data;
};
export default getFilingCounty;