import { DEPLOYMENT_TIER, NOODLE_CREATOR_ID } from '@configuration/client';

export type FEATURE_FLAG_KEY =
  | 'can-edit-form-request-provider'
  | 'can-edit-noodle-application-fees'
  | 'can-see-activity-thread'
  | 'can-see-customer-view-in-dashboard'
  | 'can-trigger-step'
  | 'confido-cart'
  | 'confido-payment-plans'
  | 'dashboard-pwa-installable'
  | 'dont-hide-uploaded-docs'
  | 'for-testing-feature-flags'
  | 'new-cart'
  | 'uscis'
  | 'integrations'
  | 'can-use-inbox'
  | 'user-profile-chat'
  | 'user-profile-v3'
  | 'workflow-conversation'
  | 'workflow-nodes'
  | 'workflow-template-modal'
  | 'create-workflow-template'
  | 'can-use-ai-reader'
  | 'team-member-availability'
  | 'enable-translations'
  | 'cases-filed-demo'
  | 'can-archive-workflow-template'
  | 'can-use-noodle-questionnaire'
  | 'credit-report-attachments-enabled'
  | 'table-view'
  | 'invoice-pre-payment'
  | 'is-noodle-creator'
  | 'questionnaire-editable-table-view'
  | 'force-customer-debit'
  | 'form-request-ai-summary'
  | 'show-custom-credit-report-modal'
  | 'show-payment-plans-tab';

type Profile = { email?: string | null } | null;
export const isNoodler = (profile: Profile): boolean => (
  Boolean(profile?.email && /@noodle\.shop/.test(profile.email))
);

const enabledByEmail = (...emails: string[]) => (profile: Profile): boolean => (
  Boolean(profile?.email && emails.includes(profile.email))
);

const enabledByCreator = (...creatorIds: string[]) => (creatorId?: string | null): boolean => Boolean(creatorId && creatorIds.includes(creatorId));

const isNoodleCreator = (creatorId?: string | null): boolean => Boolean(
  creatorId && (creatorId === NOODLE_CREATOR_ID),
);

const isProduction = (): boolean => DEPLOYMENT_TIER === 'production';

const checkFeatureFlag = (featureFlagKey: FEATURE_FLAG_KEY, profile: Profile, creatorId?: string | null): boolean => {
  if (featureFlagKey === 'for-testing-feature-flags') {
    return !isProduction() || isNoodler(profile);
  }

  if (featureFlagKey === 'dashboard-pwa-installable') {
    return true;
  }

  if (featureFlagKey === 'workflow-conversation') {
    return false;
  }

  if (featureFlagKey === 'workflow-nodes') {
    return !isProduction();
  }

  if (featureFlagKey === 'integrations') {
    return true;
  }

  if (featureFlagKey === 'user-profile-v3') {
    return true;
  }

  if (featureFlagKey === 'user-profile-chat') {
    return true;
  }

  if (featureFlagKey === 'can-use-inbox') {
    return true;
  }

  if (featureFlagKey === 'workflow-template-modal') {
    return isNoodler(profile);
  }

  if (featureFlagKey === 'can-trigger-step') {
    return true;
  }

  if (featureFlagKey === 'can-edit-form-request-provider') {
    return !isProduction() || enabledByEmail('accounts@noodle.shop', 'pradeek@compleximmigration.com', 'sid.horse+test@gmail.com')(profile);
  }

  if (featureFlagKey === 'uscis') {
    return !isProduction() || enabledByCreator('clh88zh9n07oa0bmyudap1ujz')(creatorId);
  }

  if (featureFlagKey === 'can-see-activity-thread') {
    return true;
  }

  if (featureFlagKey === 'can-see-customer-view-in-dashboard') {
    return !isProduction() || enabledByEmail('accounts@noodle.shop')(profile);
  }

  if (featureFlagKey === 'create-workflow-template') {
    return enabledByEmail('accounts@noodle.shop')(profile);
  }

  if (featureFlagKey === 'confido-cart') {
    return true;
  }

  if (featureFlagKey === 'confido-payment-plans') {
    return true;
  }

  if (featureFlagKey === 'can-edit-noodle-application-fees') {
    return !isProduction() || enabledByEmail('accounts@noodle.shop')(profile);
  }

  if (featureFlagKey === 'can-use-ai-reader') {
    return isNoodler(profile);
  }

  if (featureFlagKey === 'team-member-availability') {
    return true;
  }

  if (featureFlagKey === 'enable-translations') {
    return true;
  }

  if (featureFlagKey === 'can-archive-workflow-template') {
    return isNoodler(profile);
  }

  if (featureFlagKey === 'cases-filed-demo') {
    return true;
  }

  if (featureFlagKey === 'can-use-noodle-questionnaire') {
    return isNoodler(profile);
  }

  if (featureFlagKey === 'credit-report-attachments-enabled') {
    return true;
  }

  if (featureFlagKey === 'table-view') {
    return true;
  }

  if (featureFlagKey === 'invoice-pre-payment') {
    return true;
  }

  if (featureFlagKey === 'is-noodle-creator') {
    return isNoodleCreator(creatorId);
  }

  if (featureFlagKey === 'force-customer-debit') {
    return !isProduction() || isNoodler(profile);
  }

  if (featureFlagKey === 'questionnaire-editable-table-view') {
    return false;
  }

  if (featureFlagKey === 'form-request-ai-summary') {
    return true;
  }

  if (featureFlagKey === 'show-custom-credit-report-modal') {
    return true;
  }

  if (featureFlagKey === 'show-payment-plans-tab') {
    return true;
  }

  return false;
};

export default checkFeatureFlag;
