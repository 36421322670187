import { createContext } from 'react';

type EventData = {
  test1: {
    id: string;
    data: string;
  }
  test2: {
    id: string;
    message: string;
  }
  docRequestUpdate: {
    id: string;
  };
};

type Events = keyof EventData;

type Listener<EV extends Events> = (data: EventData[EV]) => void | Promise<void>;

export type EventsContextType = {
  addListener: <EV extends Events>(event: EV, fn: Listener<EV>) => Listener<EV>;
  emit: <EV extends Events>(event: EV, data: EventData[EV]) => void;
  isInitialized: boolean;
  removeListener: <EV extends Events>(event: EV, listener: Listener<EV>) => void;
};

const EventsContext = createContext<EventsContextType>({
  addListener: (event, listener) => listener,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  emit: () => {},
  isInitialized: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeListener: () => {},
});

export default EventsContext;
