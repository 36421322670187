import { useEffect } from 'react';
import Buttons from '@components/Buttons';
import { ToastMessage, ToastTypeVariants, useToastContext, isTitleDescriptionMessage } from 'context/ToastContext';
import XCircle from '@components/Icons/XCircle';
import CheckCircle from '@components/Icons/CheckCircle';
import Warning from '@components/Icons/Warning';
import Info from '@components/Icons/Info';
import s from './Toasts.module.scss';

type ToastComponentProps = { type?: ToastTypeVariants; message: ToastMessage; id?: string };

export default function Toast({ type, message, id }: ToastComponentProps): JSX.Element {
  const { removeToast } = useToastContext();
  const messageAsToastMessage = message as ToastMessage;

  useEffect(() => {
    const to = setTimeout(() => {
      removeToast({ message });
    }, 3000);
    return () => clearTimeout(to);
  }, [removeToast, message]);

  return (
    <div className={s['message-container']}>
      {type === ToastTypeVariants.SUCCESS && <CheckCircle weight="fill" size={20} fill="var(--color-success)" />}
      {type === ToastTypeVariants.ERROR && <XCircle weight="fill" size={20} fill="var(--color-error)" />}
      {type === ToastTypeVariants.WARNING && <Warning weight="fill" size={20} fill="var(--color-warning)" />}
      {type === ToastTypeVariants.INFO && <Info weight="fill" size={20} fill="var(--color-primary)" />}
      <div className={s.message}>
        {isTitleDescriptionMessage(messageAsToastMessage)
          ? (
            <>
              <span className='caption-bold'>{messageAsToastMessage.title}</span>
              <p className='caption'>{messageAsToastMessage.description}</p>
            </>
          )
          : (
            <p className='caption-bold'>{message}</p>
          )}
      </div>
      <Buttons
        isWrapper
        onClick={() => {
          removeToast({ id, message: isTitleDescriptionMessage(message) ? message.description : message });
        }}
        className={s.close}
      >
        <XCircle weight="fill" size={16} color="var(--color-gray-50)" />
      </Buttons>
    </div>
  );
}
