import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

const PATH_TEMPLATE = '/questionnaire-templates/{creatorId}/section-templates/{questionnaireTemplateId}';
type ThisApi = ApiDefinition[typeof PATH_TEMPLATE]['PUT'];
type Input = ThisApi['params'] & { data: ThisApi['body'] };

const saveSection = async ({ creatorId, questionnaireTemplateId, data: body }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>(PATH_TEMPLATE, {
    creatorId,
    questionnaireTemplateId,
  });
  const data = await formsBackendClient.put(path, body);
  return data.data;
};

export default saveSection;
