import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

const PATH_TEMPLATE = '/questionnaire-templates/{creatorId}/section-templates/{questionnaireTemplateId}';
type ThisApi = ApiDefinition[typeof PATH_TEMPLATE]['DELETE'];
type Input = ThisApi['params'] & ThisApi['query'];

const deleteSection = async ({ creatorId, questionnaireTemplateId, sectionTemplateId }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>(PATH_TEMPLATE, {
    creatorId,
    questionnaireTemplateId,
  });
  const response = await formsBackendClient.delete(path, { params: { sectionTemplateId } });
  return response.data;
};

export default deleteSection;
