import { useContext } from 'react';
import EventsContext, { EventsContextType } from './Context';

type ReturnValue = Pick<EventsContextType, 'addListener' | 'removeListener' | 'emit'>;

const useEvents = (): ReturnValue => {
  const { addListener, emit, removeListener } = useContext(EventsContext);
  return { addListener, emit, removeListener };
};

export default useEvents;
