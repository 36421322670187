import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/questionnaire-templates/{creatorId}/section-reorder/{questionnaireTemplateId}/']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const reorderSectionQuestionnaire = async ({ questionnaireTemplateId, order, creatorId }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/questionnaire-templates/{creatorId}/section-reorder/{questionnaireTemplateId}/', {creatorId, questionnaireTemplateId });
  const data = await formsBackendClient.put(path, {order});
  return data.data;
};

export default reorderSectionQuestionnaire;
