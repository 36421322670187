import { useMemo, useState } from 'react';
import Emittery from 'emittery';
import EventsContext, { EventsContextType } from './Context';

const emitter = new Emittery();

const emit: EventsContextType['emit'] = (event, data) => {
  emitter.emit(event, data);
};

const addListener: EventsContextType['addListener'] = (event, listener) => {
  emitter.on(event, listener);
  return listener;
};

const removeListener: EventsContextType['removeListener'] = (event, listener) => {
  emitter.off(event, listener);
};

const EventsProvider: React.FC = ({ children }) => {
  const [isInitialized] = useState(true);
  const value = useMemo(() => ({
    addListener,
    emit,
    isInitialized,
    removeListener,
  }), [isInitialized]);
  return (
    <EventsContext.Provider value={value}>
      {children}
    </EventsContext.Provider>
  );
};

export default EventsProvider;
