import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/user-workflows/{userWorkflowId}/notes';
type THIS_API = ApiDefinition['/workflows/user-workflows/{userWorkflowId}/notes']['POST'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const createUserWorkflowNote = async ({ userWorkflowId, ...body }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { userWorkflowId });
  const response = await noodleApiClient.post(path, body);
  return response.data;
};

export default createUserWorkflowNote;
